import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import { Segment, Icon } from "semantic-ui-react";
import BlogPostPreview from "../components/BlogPostPreview";
import * as colors from "../utils/colors";
import { media } from "../utils/mediaSizr";


const BlogPreviewWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	/* exclamation icon */
	i {
		color: ${colors.ui.solid};
	}
	/* link-out */
	a {
		color: ${colors.ui.solid};
		text-decoration: none;
		border-bottom: 1px solid ${colors.ui.alpha(.5)};

		:hover {
			color: ${colors.text.solid};
			border-bottom: 2px solid transparent;
			background-color: ${colors.ui.alpha(.3)};
		}
	}
`;

const BlogPreviewContainer = styled.div`
	opacity: .98;
	margin-bottom: 1rem;
	${media.desktop`
		width: 29vw;
	`}
	${media.tablet`
		width: 39vw;
	`}
	${media.mobile`
		width: 100%;
	`}
	:hover {
		opacity: 1;
	}
`;

class BlogPostsPage extends React.Component {
	state = {
		activeId: "",
	};

	onMouseEnter = (id) => {
		this.setState({ activeId: id });
	}

	onMouseLeave = () => {
		this.setState({ activeId: "" });
	}

	render() {
		const { allMarkdownRemark } = this.props.data;

		return (
			<Layout title="Blog">
				<Helmet title="Blog"/>
				<BlogPreviewWrapper>
					{allMarkdownRemark.edges.map(({ node }) => (
						<BlogPreviewContainer key={node.id}>
							<BlogPostPreview
								key={node.id}
								post={node}
								onMouseEnter={this.onMouseEnter}
								onMouseLeave={this.onMouseLeave}
								activeId={this.state.activeId}
							/>

						</BlogPreviewContainer>
					))}
					<BlogPreviewContainer>
						<Segment>
							<Icon name="warning"/>Very (😱very!) old&nbsp;
							<a
								href="https://kenniy.blogspot.com/"
								target="_blank"
								rel="noopener noreferrer"
							>blog posts.
							</a>
						</Segment>
					</BlogPreviewContainer>

				</BlogPreviewWrapper>
			</Layout>
		);
	}
}

BlogPostsPage.propTypes = {
	data: PropTypes.object,
};
export default BlogPostsPage;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC },
			filter: { frontmatter: { published: {eq : true}}}
			) {
			totalCount
			edges {
				node {
					...BlogPostPreview
				}
			}
		}
	}
`;