import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Card } from "semantic-ui-react";

export const PreviewContainer = styled.div`
	padding-bottom: 15px;
`;
class BlogPostPreview extends React.Component {
	render() {
		const post = this.props.post;
		const {onMouseEnter, onMouseLeave, activeId} = this.props;

		return (
			//getting build-time (webpack) error when not checking for post before rendering
			!post ? <div/> :
				(<Link to={post.fields.slug}>
					<PreviewContainer>
						<Card fluid
							key={post.id}
							image={post.frontmatter.image.childImageSharp.fluid.src}
							header={post.frontmatter.title}
							meta={post.frontmatter.date.toUpperCase()}
							description={post.excerpt}
							onMouseEnter={() => onMouseEnter(post.id)}
							onMouseLeave={onMouseLeave}
							raised={post.id===activeId}
						/>
					</PreviewContainer>
				</Link>)
		);
	}
}

BlogPostPreview.propTypes = {
	post: PropTypes.object,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	activeId: PropTypes.string
};
export default BlogPostPreview;

export const BlogPostPreviewFragment = graphql`
	fragment BlogPostPreview on MarkdownRemark {
		id
		frontmatter {
			image {
				childImageSharp {
					fluid(maxWidth: 786) {
						...GatsbyImageSharpFluid
					}
				}
				}
			published
			title
			date(formatString: "ddd, MMM D")
		}
		fields {
			slug
		}
		excerpt(pruneLength: 200)
	}
`;